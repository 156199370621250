import styled from 'styled-components';
import { screen } from '../../common/styles/helpers';

const StyledReCaptcha = styled.div`
    position: fixed;
    right: 0;
    bottom: 3.75rem;

    @media (max-width: ${screen.MOBILE}) {
        transform: scale(0.85);
    }
`;

export default StyledReCaptcha;
